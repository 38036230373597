import { CDN } from '@/resources/constants';

export enum category {
  'attack',
  'universal',
  'defender'
}

export interface Hero {
  id: string;
  name: string;
  category: category;
  description?: string;
  characteristics: {
    speed: number;
    control: number;
    accuracy: number;
    stamina: number;
    power: number;
  };
  nameImage: string;
  fullImage: string;
  smallImage: string;
  wip: boolean;
  newHero?: boolean;
  nameEol?: string;
}

const folderPath = '/assets/images/hero';

export const Heroes: Hero[] = [
  {
    id: 'Ming',
    name: 'Ming',
    category: category.attack,
    characteristics: {
      speed: 5,
      control: 3,
      accuracy: 4,
      stamina: 2,
      power: 2,
    },
    nameImage: '/images/names/ming_eng.svg',
    nameEol: `${ CDN + folderPath }/names/Min.svg`,
    fullImage: `${ CDN + folderPath }/big/MinNew.png`,
    smallImage: `${ CDN + folderPath }/miniatures/MinNew.png`,
    wip: false,
  },
  {
    id: 'Pai',
    name: 'Pai',
    category: category.attack,
    characteristics: {
      speed: 5,
      control: 2,
      accuracy: 5,
      stamina: 2,
      power: 1,
    },
    nameImage: '/images/names/pai_eng.svg',
    nameEol: `${ CDN + folderPath }/names/Paj.svg`,
    fullImage: `${ CDN + folderPath }/big/Paj.png`,
    smallImage: `${ CDN + folderPath }/miniatures/Paj.png`,
    wip: false,
  },
  {
    id: 'Blade',
    name: 'Blade',
    category: category.attack,
    characteristics: {
      speed: 4,
      control: 1,
      accuracy: 5,
      stamina: 2,
      power: 2,
    },
    nameImage: '/images/names/blade_eng.svg',
    nameEol: `${ CDN + folderPath }/names/Blejd.svg`,
    fullImage: `${ CDN + folderPath }/big/Blejd.png`,
    smallImage: `${ CDN + folderPath }/miniatures/Blejd.png`,
    wip: false,
  },
  {
    id: 'Murphy',
    name: 'Murphy',
    category: category.attack,
    characteristics: {
      speed: 4,
      control: 1,
      accuracy: 5,
      stamina: 4,
      power: 2,
    },
    nameImage: '/images/names/murphy_eng.svg',
    nameEol: `${ CDN + folderPath }/names/Myorfi.svg`,
    fullImage: `${ CDN + folderPath }/big/Myorfi.png`,
    smallImage: `${ CDN + folderPath }/miniatures/Myorfi.png`,
    wip: false,
  },
  {
    id: 'Pink',
    name: 'Pink',
    category: category.attack,
    characteristics: {
      speed: 5,
      control: 2,
      accuracy: 3,
      stamina: 2,
      power: 2,
    },
    nameImage: '/images/names/pink_eng.svg',
    nameEol: `${ CDN + folderPath }/names/Pink.svg`,
    fullImage: `${ CDN + folderPath }/big/Pink.png`,
    smallImage: `${ CDN + folderPath }/miniatures/Pink.png`,
    wip: false,
  },
  {
    id: 'Sky',
    name: 'Sky',
    category: category.attack,
    characteristics: {
      speed: 3,
      control: 5,
      accuracy: 4,
      stamina: 2,
      power: 3,
    },
    nameImage: '/images/names/sky_eng.svg',
    nameEol: `${ CDN + folderPath }/names/Skaj.svg`,
    fullImage: `${ CDN + folderPath }/big/Skaj.png`,
    smallImage: `${ CDN + folderPath }/miniatures/Skaj.png`,
    wip: false,
  },
  {
    id: 'Kenny',
    name: 'Kenny',
    category: category.universal,
    characteristics: {
      speed: 4,
      control: 2,
      accuracy: 4,
      stamina: 3,
      power: 3,
    },
    nameImage: '/images/names/kenny_eng.svg',
    nameEol: `${ CDN + folderPath }/names/Kenny.svg`,
    fullImage: `${ CDN + folderPath }/big/Kenny.png`,
    smallImage: `${ CDN + folderPath }/miniatures/Kenny.png`,
    wip: false,
  },
  {
    id: 'Lang',
    name: 'Lang',
    category: category.universal,
    characteristics: {
      speed: 4,
      control: 3,
      accuracy: 2,
      stamina: 5,
      power: 3,
    },
    nameImage: '/images/names/lang_eng.svg',
    nameEol: `${ CDN + folderPath }/names/Lan.svg`,
    fullImage: `${ CDN + folderPath }/big/Lan.png`,
    smallImage: `${ CDN + folderPath }/miniatures/Lan.png`,
    wip: false,
  },
  {
    id: 'Eriel',
    name: 'Eriel',
    category: category.universal,
    characteristics: {
      speed: 4,
      control: 3,
      accuracy: 2,
      stamina: 4,
      power: 3,
    },
    nameImage: '/images/names/eriel_eng.svg',
    nameEol: `${ CDN + folderPath }/names/Eriel.svg`,
    fullImage: `${ CDN + folderPath }/big/Eriel.png`,
    smallImage: `${ CDN + folderPath }/miniatures/Eriel.png`,
    wip: false,
  },
  {
    id: 'Samba',
    name: 'Samba',
    category: category.universal,
    characteristics: {
      speed: 3,
      control: 4,
      accuracy: 2,
      stamina: 3,
      power: 5,
    },
    nameImage: '/images/names/samba_eng.svg',
    nameEol: `${ CDN + folderPath }/names/Samba.svg`,
    fullImage: `${ CDN + folderPath }/big/Samba.png`,
    smallImage: `${ CDN + folderPath }/miniatures/Samba.png`,
    wip: false,
  },
  {
    id: 'Lydia',
    name: '???',
    category: category.universal,
    description: '???',
    characteristics: {
      speed: 0,
      control: 0,
      accuracy: 0,
      stamina: 0,
      power: 0,
    },
    nameImage: '/images/names/soon_v2.svg',
    nameEol: `${ CDN + folderPath }/names/Soon.svg`,
    fullImage: `${ CDN + folderPath }/big/Lidiya.png`,
    smallImage: `${ CDN + folderPath }/miniatures/Lidiya.png`,
    wip: true,
  },
  {
    id: 'Janus',
    name: '???',
    category: category.universal,
    description: '???',
    characteristics: {
      speed: 0,
      control: 0,
      accuracy: 0,
      stamina: 0,
      power: 0,
    },
    nameImage: '/images/names/soon_v2.svg',
    nameEol: `${ CDN + folderPath }/names/Soon.svg`,
    fullImage: `${ CDN + folderPath }/big/Yanus.png`,
    smallImage: `${ CDN + folderPath }/miniatures/Yanus.png`,
    wip: true,
  },
  {
    id: 'Gridiron',
    name: 'Gridiron',
    category: category.defender,
    characteristics: {
      speed: 1,
      control: 2,
      accuracy: 1,
      stamina: 3,
      power: 4,
    },
    nameImage: '/images/names/gridiron_eng.svg',
    nameEol: `${ CDN + folderPath }/names/Soon.svg`,
    fullImage: `${ CDN + folderPath }/big/Gridiron.png`,
    smallImage: `${ CDN + folderPath }/miniatures/Gridiron.png`,
    wip: false,
  },
  {
    id: 'K1-D',
    name: 'K1-D',
    category: category.defender,
    characteristics: {
      speed: 2,
      control: 2,
      accuracy: 1,
      stamina: 4,
      power: 4,
    },
    nameImage: '/images/names/k1d_eng.svg',
    nameEol: `${ CDN + folderPath }/names/Soon.svg`,
    fullImage: `${ CDN + folderPath }/big/K1D.png`,
    smallImage: `${ CDN + folderPath }/miniatures/K1D.png`,
    wip: false,
  },
  {
    id: 'R47',
    name: 'R47',
    category: category.defender,
    characteristics: {
      speed: 2,
      control: 5,
      accuracy: 1,
      stamina: 5,
      power: 4,
    },
    nameImage: '/images/names/r47_eng.svg',
    nameEol: `${ CDN + folderPath }/names/Gridiron.svg`,
    fullImage: `${ CDN + folderPath }/big/R47.png`,
    smallImage: `${ CDN + folderPath }/miniatures/R47.png`,
    wip: false,
  },
  {
    id: 'Hallie',
    name: 'Hallie',
    category: category.defender,
    characteristics: {
      speed: 1,
      control: 4,
      accuracy: 1,
      stamina: 2,
      power: 5,
    },
    nameImage: '/images/names/hallie_eng.svg',
    nameEol: `${ CDN + folderPath }/names/K1D.svg`,
    fullImage: `${ CDN + folderPath }/big/Holli.png`,
    smallImage: `${ CDN + folderPath }/miniatures/Holli.png`,
    wip: false,
  },
  {
    id: 'Perun',
    name: '???',
    category: category.defender,
    description: '???',
    characteristics: {
      speed: 0,
      control: 0,
      accuracy: 0,
      stamina: 0,
      power: 0,
    },
    nameImage: '/images/names/soon_v2.svg',
    nameEol: `${ CDN + folderPath }/names/Soon.svg`,
    fullImage: `${ CDN + folderPath }/big/Perun.png`,
    smallImage: `${ CDN + folderPath }/miniatures/Perun.png`,
    wip: true,
  },
];
