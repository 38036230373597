<script setup lang="ts">
import { addGtmTag, isAllowAdvertising } from '@/helpers/helpersFunctions';

const isAllowGTM = isAllowAdvertising();
if (isAllowGTM) {
  addGtmTag();
}
</script>

<template>
  <Suspense>
    <router-view v-slot="{ Component }">
      <component :is="Component" />
    </router-view>
  </Suspense>
</template>

<style lang="scss">

</style>
