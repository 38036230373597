import type { SwiperProps } from 'swiper/react';

export const CDN: string = 'https://base.servicecdn.ru/staticContent/lend2';

export enum ROUTE_NAMES {
  HOME_GLOBAL = 'main_global',
}

export const triangleBlue = 'url(/images/decor/triangle-blue-v2.svg)';
export const triangleSmall = 'url(/images/decor/triangle-red-2.svg)';

export const characteristicsMetrics: string[] = [
  'speed',
  'control',
  'accuracy',
  'stamina',
  'power',
];

const imgFolderPath = '/assets/images';
export const attackBg = `url(${ CDN + imgFolderPath }/attack-bg.png)`;
export const defenderBg = `url(${ CDN + imgFolderPath }/defender-bg.png)`;
export const universalBg = `url(${ CDN + imgFolderPath }/universal-bg.png)`;
export const pinkLine1 = 'url(/images/decor/pink-line-1-v2.svg)';
export const fogMobile = `url(${ CDN + imgFolderPath }/fog-mobile.png)`;
export const blueLine = 'url(/images/decor/blue-line-v2.svg)';
export const decorBottom = 'url(/images/decor/heroes-decor-bottom-v2.svg)';
export const decorBottomMobile = 'url("/images/decor/heroes-decor-bottom-mobile-v2.svg")';
export const glassBottom = 'url(/images/decor/heroes-glass-bottom.svg)';
export const glassBottomMobile = 'url("/images/decor/heroes-glass-bottom-mobile-v2.svg")';
export const strokeWhite = `url(${ CDN + imgFolderPath }/stroke-white.svg)`;
export const strokeAttack = 'url(/images/stroke-attack.svg)';
export const strokeUniversal = 'url(/images/stroke-universal.svg)';
export const strokeDefender = 'url(/images/stroke-defender.svg)';
export const arrow = `url(${ CDN + imgFolderPath }/arrow.svg)`;
export const polygons = 'url(/images/decor/polygons.svg)';
export const pinkLinePopup1023 = 'url(/images/decor/pink-line-popup-1023.svg)';
export const polygonsMobile = 'url(/images/decor/polygons.svg)';

export const closeImgPath = `${ CDN + imgFolderPath }/close.svg`;
export const close = `url(${ closeImgPath })`;

export const swiperParams: SwiperProps = {
  slidesPerView: 1,
  centeredSlides: true,
  direction: 'horizontal',
  effect: 'fade',
  fadeEffect: {
    crossFade: true,
  },
  loop: false,
  pagination: {
    type: 'fraction',
  },
  navigation: true,
  breakpoints: {
    320: {
      allowTouchMove: true,
    },
    1024: {
      allowTouchMove: false,
    },
  },
  injectStyles: [
    `
      .swiper-pagination-current {
          color: #FFFFFF;
      }
      .swiper-button-prev,
      .swiper-button-next {
          svg {
              display: none;
          }
      }

      .swiper-button-prev.swiper-button-disabled,
      .swiper-button-next.swiper-button-disabled {
          background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEzIDIwTDE4LjQ5MDIgMzBIMjdMMjEuNTA5OCAyMEMxOC42NzMyIDIwIDE1LjgzNjYgMjAgMTMgMjBaIiBmaWxsPSIjMkEyQzJGIi8+CjxwYXRoIGQ9Ik0xMyAyMEwxOC40OTAyIDEwTDI3IDEwTDIxLjUwOTggMjBDMTguNjczMiAyMCAxNS44MzY2IDIwIDEzIDIwWiIgZmlsbD0iIzJBMkMyRiIvPgo8L3N2Zz4K) !important;
      }
        `,
  ],
};

export const classForActiveJSModal: string = 'js_modalActive';

export const linkPrivacyPolicy = 'https://baseplatform.online/legal/base/26e58580-d9de-4c7f-904c-9c11afb57094';
export const fieldNameCookiePolicy = 'superball-cookie-policy';
