import Cookies from 'js-cookie';
import { fieldNameCookiePolicy } from '@/resources/constants';

import { Heroes, category } from '@/resources/Heroes';
import type { Hero } from '@/resources/Heroes';

function filterHeroes(categoryItem: category): Hero[] {
  const heroList: Hero[] = [];
  Heroes.filter((hero) => (hero.category === categoryItem ? heroList.push(hero) : ''));
  return heroList;
}

const attackHeroes = filterHeroes(category.attack);
const universalHeroes = filterHeroes(category.universal);
const defenderHeroes = filterHeroes(category.defender);

export { attackHeroes, universalHeroes, defenderHeroes };

export function addGtmTag() {
  // eslint-disable-next-line operator-linebreak
  const gtmScriptNode = document.createTextNode('(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({\'gtm.start\':\n' +
    'new Date().getTime(),event:\'gtm.js\'});var f=d.getElementsByTagName(s)[0],\n'
    + 'j=d.createElement(s),dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';j.async=true;j.src=\n'
    + '\'https://www.googletagmanager.com/gtm.js?id=\'+i+dl;f.parentNode.insertBefore(j,f);\n'
    + '})(window,document,\'script\',\'dataLayer\',\'GTM-M964BHF2\');');

  const scriptEl = document.createElement('script');
  scriptEl.appendChild(gtmScriptNode);

  document.head.appendChild(scriptEl);
}

export function getCookie(name: string): string | undefined {
  return Cookies.get(name);
}

export function setCookie(name: string, value: string, options?: Cookies.CookieAttributes): string | undefined {
  return Cookies.set(name, value, options);
}

function getFromCookiePolicy(field: string): object | string | boolean {
  const cookiePolicy = Cookies.get(fieldNameCookiePolicy);
  if (!cookiePolicy) {
    return false;
  }

  let value;
  try {
    const rawPolicy = JSON.parse(cookiePolicy);
    return rawPolicy?.find((i: {
      policy: string
    }) => i.policy === field).value === true || false;
  } catch {
    Cookies.remove(fieldNameCookiePolicy);
    value = false;
  }

  return value;
}

export function isAllowAdvertising(): boolean {
  return !!getFromCookiePolicy('advertising');
}

export function isAllowPersonalization(): boolean {
  return !!getFromCookiePolicy('personalization');
}
