import { type Ref, ref } from 'vue';
import type { Feature } from '@/resources/Features';
import type { Hero } from '@/resources/Heroes';

export type language = 'en' | 'sp' | 'pt' | 'fr' | 'de' | 'ru' | 'ar' | 'chi' | 'jp' | 'kr' | 'zho';

export const allLangs: language[] = [
  'en',
  'sp',
  'pt',
  'fr',
  'de',
  'ru',
  'ar',
  'chi',
  'jp',
  'kr',
  'zho',
];

const locales: any = {
  en: import('./locale/en'),
  sp: import('./locale/sp'),
  pt: import('./locale/pt'),
  fr: import('./locale/fr'),
  de: import('./locale/de'),
  ru: import('./locale/ru'),
  ar: import('./locale/ar'),
  chi: import('./locale/chi'),
  jp: import('./locale/jp'),
  kr: import('./locale/kr'),
  zho: import('./locale/zho'),
};

export async function localisation(locale: language) {
  if (allLangs.includes(locale)) {
    const newLocaleResourceModule = await locales[locale];
    return newLocaleResourceModule.default;
  }

  return false;
}

export type LocaleTypeObject = {
  [key: string]: string & Feature[] & Partial<Hero>[];
};

/**
 * Динамически загружает файл локализации, заливает локализацию в стор для дальнейшего использования.
 * @param lang
 * @param store
 */
export async function setLocale(lang: language, store: { locale: LocaleTypeObject, currentLocale: Ref<language> }) {
  const t = await localisation(lang);

  t.forEach((locale: string & Feature[] & Partial<Hero>[], index: string) => {
    store.locale[index] = locale;
  });

  store.currentLocale = ref(lang);
}

export type LocaleType = string | Feature[] | Partial<Hero>[];
