import { createRouter, createWebHistory } from 'vue-router';
import { ROUTE_NAMES } from '@/resources/constants';
import { isAllowPersonalization, getCookie } from '@/helpers/helpersFunctions';
import { type language, localisation, allLangs } from '@/resources/i18n';

const MainPage = () => import('@/pages/MainPage.vue');

const routes = [
  {
    path: '/:lang',
    name: ROUTE_NAMES.HOME_GLOBAL,
    component: MainPage,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: () => {
      let langUrl = '/en';
      if (isAllowPersonalization()) {
        const cookieLang = getCookie('lang');
        if (cookieLang) {
          langUrl = `/${ cookieLang }`;
        }
      }
      return { path: langUrl };
    },
  },
];

export const router = createRouter({
  history: createWebHistory('./'),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const newLang: language = to.params.lang.toString()
    .toLowerCase() as language;
  const isNeedRedirect = !(allLangs.includes(newLang));
  if (isNeedRedirect) {
    next({ path: '/en' });
  }

  const t = await localisation(newLang);

  document.title = t.get('meta_title');
  document.querySelector('head meta[name="description"]')
    ?.setAttribute('content', t.get('meta_description'));
  document.querySelector('head meta[property="og:description"]')
    ?.setAttribute('content', t.get('meta_smm_share'));

  next();
});
